<template>
    <v-flex xs6 class="line-button">
        <v-btn
            color="#00C300"
            large
            dark
            block
            @click="Line_login"
            class="btn-small-right"
        >
            <!-- <v-icon left>facebook</v-icon> -->
            <img src="@/assets/Line_icon.png" width="32" class="line-icon" />
            {{ $t('lineLogin.line-login') }}
        </v-btn>
    </v-flex>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'line-login',
    data() {
        return {
            state: ''
        }
    },
    methods: {
        Line_login() {
            this.state = this.generateRandomString()

            //client_id
            //正式：1655312187
            //測試：1655283467
            //本地：2000432034
            let client_id = '1655312187'

            //redirect_uri
            //正式：https://bboxlocker.com/${i18n.locale}/login
            //測試：https://172.104.51.246/${i18n.locale}/login
            //本地：https://192.168.0.6:8080/${i18n.locale}/login
            let redirect_uri = `https://bboxlocker.com/${i18n.locale}/login`

            let link = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code'

            // chanelId
            link += '&client_id=' + client_id

            //要接收回傳訊息的網址
            link += '&redirect_uri=' + redirect_uri

            //用來防止跨站請求的，之後會回傳回來，用作驗證
            link += `&state=${this.state}`

            //跟使用者要求的權限，目前就三個能選 openid profile email
            link += '&scope=openid%20profile%20email'

            //正式：&nonce=bbox
            //測試：&nonce=test
            //本地：&nonce=local
            link += '&nonce=bbox'

            //是否要加入官方好友
            // link += '&bot_prompt=aggressive'
            link += '&bot_prompt=normal'
            
            //是否要強制顯示同意授權頁面
            // link += '&prompt=consent'

            window.location.href = link
        },
        generateRandomString(length = 10){
            let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

            let string = ""

            for (let i = 0; i < length; i++) {
                string += chars.charAt(Math.floor(Math.random() * chars.length))
            }

            return string
        }
    }
}
</script>

<style scoped>
.btn-small-right {
    width: 98%;
    position:relative;
    right: 0px;
}
.line-icon {
    margin-right: 16px;
}
</style>