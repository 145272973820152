<template>
        <v-flex xs12 style="margin-top: -13px;margin-bottom: 6px; ">
            <div class="google-btn" v-on:click="login">
                <div class="google-icon-wrapper">
                    <img class="google-icon" src="@/assets/Google_icon.png"/>
                </div>
                <p class="btn-text"><b>{{googleContent}}</b></p>
            </div>
            <message-dialog
                :show.sync="msgDialog.show"
                :msg="msgDialog.msg"
                :primary-btn="msgDialog.primaryBtn"
                :secondary-btn="msgDialog.secondaryBtn"
                @primary="closeMsgDialogFunc"
            ></message-dialog>
        </v-flex>
</template>

<script>
// import store from '@/store'
import MessageDialog from '@/components/MessageDialog'
import MessageDialogControl from '@/mixins/MessageDialogControl'
import i18n from '@/i18n'
import { HostEnv, CurrentUri } from '@/store'

export default {
    name: 'google-login',
    components: { MessageDialog },
    mixins: [ MessageDialogControl ],
    data() {
        return {
            closeMsgDialogFunc: () => {},
            access_token: '',
            googleContent: i18n.t('googleLogin.google-login'),
            googleCanClick: true,
            googleTime: 10
        }
    },
    //mounted() {},
    methods: {
        login() {
            let redirect_uri=`https://bboxlocker.com`+CurrentUri
            let google_client_id = '257690519097-36ueglnf92141jr3g7t8ripc8ldk6b51.apps.googleusercontent.com'
            let agent = navigator.userAgent
            let ex_browser = ""
            let bool_browser = (agent.indexOf('Line') !== -1) || (agent.indexOf("FB_IAB") !== -1 || agent.indexOf("FBAN") !== -1 || agent.indexOf("FBAN") !== -1) // || (agent.indexOf("WeChat") !== -1 || agent.indexOf("MicroMessenger") !== -1);
            if (bool_browser){
                ex_browser = "&openExternalBrowser=1"
                google_client_id += ex_browser
            }
            if (HostEnv !== 'bbox') {
                redirect_uri=`http://` + location.hostname +`:8080`+CurrentUri
                google_client_id = '1090789759432-v3gij3maumtljbub28cfouihe7kamgv2.apps.googleusercontent.com'
                google_client_id += ex_browser
            }
            location.href=`https://accounts.google.com/o/oauth2/v2/auth?response_type=code&scope=email profile&client_id=${google_client_id}&redirect_uri=${redirect_uri}&access_type=offline&prompt=consent`
        }
    }
}
</script>

<style scoped>
.btn-small {
    width: 98%;
}
.google-btn {
  width: 99%;
  height: 44px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.google-btn .btn-text {
  padding-top: 11px;
  text-align: center;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: #1669F2;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
</style>