<template>
        <v-flex xs6>
            <v-btn
                color="primary"
                large
                block
                :disabled="!facebookCanClick"
                @click="FB_login"
                class="btn-small"
            >
                <v-icon left>facebook</v-icon>
                {{ facebookContent }}
            </v-btn>
            <message-dialog
                :show.sync="msgDialog.show"
                :msg="msgDialog.msg"
                :primary-btn="msgDialog.primaryBtn"
                :secondary-btn="msgDialog.secondaryBtn"
                @primary="closeMsgDialogFunc"
            ></message-dialog>
        </v-flex>
</template>

<script>
import store from '@/store'
import MessageDialog from '@/components/MessageDialog'
import MessageDialogControl from '@/mixins/MessageDialogControl'
import i18n from '@/i18n'

export default {
    name: 'facebook-login',
    components: { MessageDialog },
    mixins: [ MessageDialogControl ],
    data() {
        return {
            closeMsgDialogFunc: () => {},
            access_token: '',
            facebookContent: i18n.t('facebookLogin.facebook-login'),
            facebookCanClick: true,
            facebookTime: 10
        }
    },
    methods: {
        FB_login() {
            //防止連點facebook登入鈕
            this.facebookCanClick = false
            this.facebookContent = i18n.t('facebookLogin.re-login') + `(${this.facebookTime})`

            let clock = window.setInterval(() => {
                this.facebookTime--
                this.facebookContent = i18n.t('facebookLogin.re-login') + `(${this.facebookTime})`
                if (this.facebookTime < 0) {
                    window.clearInterval(clock)
                    this.facebookContent = i18n.t('facebookLogin.facebook-login')
                    this.facebookTime = 10
                    this.facebookCanClick = true
                }
            }, 1000)

            const vm = this 
            // 檢查登入狀態
            //eslint-disable-next-line
            FB.getLoginStatus(function(response) {
                // 登入狀態 - 已登入
                if (response.status === 'connected') {
                    // 檢查有無 email 授權
                    if (response.authResponse.grantedScopes) {
                        if (response.authResponse.grantedScopes.indexOf('email') === -1) {
                            vm.login()
                        } else {
                            // 獲取用戶個人資料
                            vm.getProfile()
                        }
                    } else {
                        vm.login()
                    }
                } else if (response.status === 'not_authorized' || response.status === 'unknown') {
                    // 登入狀態 - 未登入
                    vm.login()
                } else {
                    // 錯誤狀況
                    store.commit('setSnackBar', {
                        message: i18n.t('facebookLogin.facebook-login-failed'),
                        color: 'error'
                    })
                }
            })
        },
        login() {
            const vm = this
            // 用戶登入(確認授權)
            //eslint-disable-next-line
            FB.login(function(response) {
                // 檢查有無 eamil 授權
                if (response.authResponse) {
                    if (response.authResponse.grantedScopes.indexOf('email') === -1) {
                        vm.showMsgDialog(i18n.t('facebookLogin.authorize-email'), i18n.t('facebookLogin.close'))
                    } else {
                        vm.statusChangeCallback(response)
                    }
                }
            },
            // 授權 - 個人資料&Email
            {
                scope: "public_profile,email,user_gender,user_age_range,user_hometown,user_birthday",
                return_scopes: true,
                auth_type: "rerequest"
            })
        },
        statusChangeCallback(response) {
            //The current status of the person
            if (response.status === 'connected') {
                this.access_token = response.authResponse.accessToken
                this.getProfile()
            } else {
                store.commit('setSnackBar', {
                    message: i18n.t('facebookLogin.facebook-login-failed'),
                    color: 'error'
                })
            }
        },
        getProfile() {
            const vm = this
            //取得使用者資料
            //eslint-disable-next-line
            FB.api('/me?fields=name,id,email,gender,age_range,birthday', function(response) {
                if (response.error) {
                    store.commit('setSnackBar', {
                        message: i18n.t('facebookLogin.facebook-login-failed'),
                        color: 'error'
                    })
                } else {
                    vm.$store.dispatch('socialLogin', {
                        social_id: response.id,
                        name: response.name,
                        email: response.email,
                        social: 2,
                        info: response,
                        token: vm.access_token
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.btn-small {
    width: 98%;
}
</style>